 import AreaCard from '../../components/AreaCard';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Hero from '../../components/Hero';
import ImageSlider from '../../components/Slider';
import Title from  '../../components/Title'
import './styles.css'

//página de apresentação do site
export default function Presentation() {

    const conteudoAreasAtuacao = [
        {title: "Caracterização Microestrutural:", content: "Análise detalhada de amostras metálicas e compósitos com microscópios ópticos de alta precisão."},
        {title: "Testes Mecânicos:", content: "Determinação de dureza e resistência utilizando equipamentos como o durômetro Rockwell."},
        {title: "Preparação de Amostras:", content: "Realizada com cortadoras metalográficas, embutidoras e polimento de superfícies para garantir alta qualidade nas análises subsequentes."},
        {title: "Estudos Térmicos:", content: "Ensaios com o forno mufla para investigar alterações microestruturais a partir de tratamentos térmicos."}
    ]

    const principaisTecnicas = [ 
        {title: "Polimento e lixamento de amostras para preparação metalográfica.", content: ""}, 
        {title: "Inspeção óptica detalhada com microscópios trinoculares e estereoscópicos.", content: ""}, 
        {title: "Ensaios de dureza Rockwell para avaliação de propriedades mecânicas.", content: ""}, 
        {title: "Corte metalográfico de precisão para análise microestrutural refinada.", content: ""}, 
    ]

    const images = ["/img/lab_inter_b.jpeg", "/img/lab_inter_c.jpeg"]

    return (
        <section className='presentation-container'>
            <Header />
            <Hero title={'Laboratório de Materiais'} subTitle={'Ambiente Multiusuário | PNIPE-MCTI'} />

            <section className='content-container' >
                <Title content={'Apresentação'} />

                <p>O Laboratório de Materiais do IFPB Campus Cajazeiras é um ambiente multiusuário certificado pela Plataforma Nacional de Infraestrutura de Pesquisa (PNIPE/MCTI), dedicado à caracterização avançada de materiais. Localizado em um espaço de 43 m², o laboratório está equipado com infraestrutura moderna, incluindo sistemas de análise de alta precisão e ferramentas para avaliação microestrutural e de integridade de superfícies. Esse espaço é voltado para o ensino, pesquisa, inovação e prestação de serviços, consolidando-se como referência no avanço científico e no desenvolvimento tecnológico.</p>

                <div className='area-content-container'>
                    <AreaCard title={"Área de atuação"} content={conteudoAreasAtuacao}/>

                    <AreaCard title={"Principais Técnicas"} content={principaisTecnicas}/>
                </div>

                <ImageSlider images={images} />

            </section>
            <Footer />
        </section>
    );
}