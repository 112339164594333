import FooterContent from './Content';
import './styles.css'

export default function Footer() {
    return (
        <footer className='footer-container'>
            <img className='logo-lab-footer' src="/img/lab_mat_white_logo.png" alt="logo do laboratorio de materiais" />

            <FooterContent />

            <div className='footer-contacts'>
                <div>
                    <img src="/icons/email_icon.png" alt="email" />
                    <p>lm.cz@ifpb.edu.br</p>
                </div>
                <div>
                    <img src="/icons/location_icon.png" alt="localizacao" />
                    <p>Rua José Antônio da Silva, 300, Cajazeiras - PB</p>
                </div>
            </div>
        </footer>
    );
}