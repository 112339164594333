import './styles.css'
import { Link } from 'react-router-dom';
import Documents from '../../pages/Documents';

export default function Navigation() {
    return (
        <nav className='navigation-container'>

            <Link to={'/'} >Apresentação</Link>

            {/*adicionar rotas (to={'/...'}) conforme as página serão desenvolvidas*/}
            <Link to={'/search'} >Pesquisa</Link>

            <Link to={'/structure-and-machines'} >Estrutura e Equipamentos</Link>

            <Link to={'/documents-uteis'} element={<Documents />}>Documentos Úteis</Link>

            <Link to={'/contact'}>Contato/Agendamento</Link>

        </nav>
    );
}